.iconSize {
    width: 24px;
    height: 24px;
    display: flex;
}

.loginForm {
    width: 100%;
}

.loginHeader {
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
}

.forgotPasswordLink {
    display: flex;
    justify-content: flex-end;

    & button[class*='Button'] {
        padding-right: 0;
    }
}