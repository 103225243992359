.p180container {
    margin: 0 auto;
    border: 1px solid var(--color-grey-300);
    padding: var(--spacing-7);
    width: 100%;
    max-width: 500px;
    font-size: var(--font-size-3xl);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: var(--spacing-5);

    @media --xs {
        padding: var(--spacing-7) var(--spacing-4);
    }
}