.iconSize {
    width: 24px;
    height: 24px;
    display: flex;
}

.forgotForm {
    width: 100%;
}

.forgotHeader {
    display: flex;
    gap: var(--spacing-2);
    align-items: center;
}

.loginLink {
    display: flex;
    justify-content: flex-start;

    & button[class*='Button'] {
        padding-left: 0;
    }
}